<template>
  <section class="hero-stack wf-section">
    <div class="container-2">
      <div v-if="loading" class="hero-wrapper-two custom-loader-wrapper">
        <div v-if="NotFound">
          <h1 style="margin: 0">No Blog Found</h1>
        </div>
        <div v-else class="custom-loader"></div>
      </div>
      <div class="hero-wrapper-two" v-if="this.$route.query.id && !loading">
        <!-- <h1 class="newsheading">
          {{ obj.title }}
        </h1> -->
        <img
          :src="obj.image"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, (max-width: 1439px) 85vw, 1140px"
          alt=""
          class="hero-image shadow-two"
        />

        <p v-html="obj.content" class="newscontent"></p>
        <Zakat v-if="obj.id == 1" />
      </div>
    </div>
  </section>
  <section class="storysection wf-section">
    <div class="container-2">
      <h1 class="centered-heading left-bordered">
        <span class="highlightheading">Sacred Acts</span>
      </h1>
      <Splide
        class="storybottomslider"
        :options="{
          omitEnd: true,
          focus: 0,
          gap: 20,
          perPage: 3,
          perMove: 1,
          type: 'slide',
          arrows: true,
          pagination: false,
          breakpoints: {
            991: {
              perPage: 3,
              gap: 7,
            },
            767: {
              perPage: 2,
            },
            479: {
              perPage: 1,
            },
          },
        }"
      >
        <SplideSlide v-for="(item, ind) in blogs" :key="ind">
          <div class="storybottomslide">
            <a :href="'/blog?id=' + item.id" target="_self">
              <img
                :src="item.image"
                loading="lazy"
                alt=""
                class="bottomimage"
              />

              <div class="bottomcontentwrapper">
                <h1 class="bottomtitle">
                  {{ item.title }}
                </h1>
                <p v-if="item.description" class="bottomparagraph blogdesc">
                  {{ item.description }}
                </p>
                <a
                  data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                  class="button-primary w-button"
                  >Read More</a
                >
              </div>
            </a>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import Zakat from "./Zakat.vue";

export default {
  name: "SingleNews",
  components: {
    Splide,
    SplideSlide,
    Zakat,
  },
  data() {
    return {
      obj: {},
      loading: false,
      NotFound: false,
      blogs: [],
    };
  },
  created() {
    // console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.loadData();
    } else {
      this.loading = true;
      this.NotFound = true;
    }
    this.loadBlogs();
  },
  methods: {
    loadData() {
      this.loading = true;
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Blogs/" + this.$route.query.id,
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((response) => {
          // console.log(response);
          if (response.data.data == "NotFound") {
            this.NotFound = true;
          } else {
            this.obj = response.data.data;
            this.loading = false;
          }
        })
        .catch(function (error) {
          // console.log(error);
          this.NotFound = true;
          // this.loading = false;
        });
    },
    loadBlogs() {
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Blogs",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          this.blogs = res.data.data.filter(
            (el) => el.id != this.$route.query.id
          );
          // console.log(this.blogs)
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style></style>
