<template>
  <aside class="footer-light wf-section">
    <div class="container-2">
      <div class="footer-wrapper-two">
        <a class="footer-brand w-inline-block">
          <img
            v-if="logoData"
            :src="logoData.logo"
            loading="lazy"
            sizes="(max-width: 479px) 40vw, 90px"
            alt=""
            style="width: 80%"
          />
          <img
            v-else
            src="@/assets/images/world-appeal-white.png"
            srcset="
              @/assets/images/world-appeal-white.png 500w,
              @/assets/images/world-appeal-white.png 578w
            "
            loading="lazy"
            sizes="(max-width: 479px) 40vw, 90px"
            alt=""
            style="width: 80%"
          />
        </a>
      </div>
      <div class="footer-wrapper-two">
        <!-- <div class="footer-block-two">
          <div class="footer-title">Appeals</div>

          <a v-for="item in appealData" :key="item" :href="item.url" class="footerlinkblock w-inline-block">
            <div>{{ item.title }}</div>
          </a>
        </div>
        <div class="footer-block-two">
          <div class="footer-title">About us</div>

          <a v-for="item in aboutData" :key="item" :href="item.url" class="footerlinkblock w-inline-block">
            <div>{{ item.title }}</div>
          </a>
        </div> -->
        <div
          class="footer-block-two"
          v-for="data in footerData"
          :key="data.footer.id"
        >
          <div class="footer-title">{{ data.footer.title }}</div>

          <a
            v-for="item in data.links"
            :key="item.id"
            :href="getRoute(item)"
            :target="item.actionType == 'url' ? '_blank' : '_self'"
            class="footerlinkblock w-inline-block"
          >
            <div>{{ item.text }}</div>
          </a>
        </div>
        <div class="footer-block-two" v-if="contactObj">
          <div class="footer-title">{{ contactObj.footer.title }}</div>
          <template v-for="item in contactObj.links">
            <template v-if="item.type == 'whatsapp'">
              <a
                class="footerlinkblock w-inline-block"
                :href="'https://wa.me/+' + item.text"
                target="_blank"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-whatsapp.svg"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
            <template v-if="item.type == 'email'">
              <a
                class="footerlinkblock w-inline-block"
                :href="'mailto:' + item.text"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-envelope-48.png"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
            <template v-if="item.type == 'location'">
              <a
                class="footerlinkblock w-inline-block"
                :href="item.link"
                target="_blank"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-location-50.png"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
          </template>

          <div class="footer-social-block-two">
            <template v-for="item in contactObj.socials">
              <template v-if="item.type == 'twitter'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 21 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.862 4.84c0-.19-.01-.369-.018-.557A8.445 8.445 0 0 0 21 2.1a8.609 8.609 0 0 1-2.475.638A4.188 4.188 0 0 0 20.419.41a8.522 8.522 0 0 1-2.734.988A4.39 4.39 0 0 0 14.543 0a4.236 4.236 0 0 0-4.312 4.243c0 .335.035.661.113.979C6.76 5.002 3.584 3.245 1.462.59a4.295 4.295 0 0 0-.587 2.174c0 1.51.763 2.851 1.921 3.636A4.313 4.313 0 0 1 .84 5.835v.059a4.379 4.379 0 0 0 3.463 4.282 4.34 4.34 0 0 1-1.134.146 4.405 4.405 0 0 1-.814-.09 4.324 4.324 0 0 0 4.025 3.032A8.619 8.619 0 0 1 0 15.038 12.048 12.048 0 0 0 6.606 17c7.92 0 12.256-6.514 12.256-12.16"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </template>
              <template v-if="item.type == 'instagram'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="currentColor" fill-rule="evenodd">
                      <path
                        d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                      ></path>
                      <path
                        d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                      ></path>
                    </g>
                  </svg>
                </a>
              </template>
              <template v-if="item.type == 'facebook'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 11 23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </template>
            </template>
          </div>
        </div>
        <div class="footer-block-two" v-else>
          <div class="footer-title">Contact</div>
          <a
            class="footerlinkblock w-inline-block"
            href="https://wa.me/+07864001000"
            target="_blank"
          >
            <div>
              <img
                src="@/assets/images/icons8-whatsapp.svg"
                loading="lazy"
                alt=""
              />
              0786 400 1000
            </div>
          </a>
          <a
            class="footerlinkblock w-inline-block"
            href="mailto:info@worldappeal.org.uk"
          >
            <div>
              <img
                src="@/assets/images/icons8-envelope-48.png"
                loading="lazy"
                alt=""
              />
              info@worldappeal.org.uk
            </div>
          </a>
          <a class="footerlinkblock w-inline-block" href="#">
            <div>
              <img
                src="@/assets/images/icons8-location-50.png"
                loading="lazy"
                alt=""
              />
              126-128 Bury Park Road Luton, LU1 1HE
            </div>
          </a>
          <div class="footer-social-block-two">
            <a
              href="https://twitter.com/worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 21 17"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.862 4.84c0-.19-.01-.369-.018-.557A8.445 8.445 0 0 0 21 2.1a8.609 8.609 0 0 1-2.475.638A4.188 4.188 0 0 0 20.419.41a8.522 8.522 0 0 1-2.734.988A4.39 4.39 0 0 0 14.543 0a4.236 4.236 0 0 0-4.312 4.243c0 .335.035.661.113.979C6.76 5.002 3.584 3.245 1.462.59a4.295 4.295 0 0 0-.587 2.174c0 1.51.763 2.851 1.921 3.636A4.313 4.313 0 0 1 .84 5.835v.059a4.379 4.379 0 0 0 3.463 4.282 4.34 4.34 0 0 1-1.134.146 4.405 4.405 0 0 1-.814-.09 4.324 4.324 0 0 0 4.025 3.032A8.619 8.619 0 0 1 0 15.038 12.048 12.048 0 0 0 6.606 17c7.92 0 12.256-6.514 12.256-12.16"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/worldappealofficial/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="currentColor" fill-rule="evenodd">
                  <path
                    d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                  ></path>
                  <path
                    d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/worldappealofficial/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 11 23"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        class="footer-bottom pt-3 flex-row flex-wrap gap-1"
        style="border-top: 1px solid gray"
      >
        <div class="footer-copyright m-0">
          © {{ year }} World Appeal. All rights reserved
        </div>
        <div class="d-flex flex-wrap gap-2">
          <a href="/privacy-policy" class="footerlinkblock w-inline-block mt-0">
            <div>Privacy Policy</div>
          </a>
          <a
            href="/terms-and-conditions"
            class="footerlinkblock w-inline-block mt-0"
          >
            <div>Terms & Conditions</div>
          </a>
        </div>
      </div>
    </div>
  </aside>
  <div
    v-if="false"
    class="back_to_top"
    :style="{ display: scrollPosition > 600 ? 'block' : 'none' }"
    @click="scrollTop"
  >
    <img
      width="30"
      height="30"
      src="@/assets/images/worldappeal/top.png"
      alt="to top"
      loading="lazy"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      scrollPosition: null,
      year: new Date().getFullYear(),
      appealData: [
        { title: "Education Fund", url: "#" },
        { title: "Food Fund", url: "#" },
        { title: "Water Fund", url: "#" },
        { title: "Muhammad's Kitchen", url: "#" },
        { title: "Aid Program", url: "#" },
      ],
      aboutData: [
        { title: "How we started", url: "#" },
        { title: "Become a Volunteer", url: "#" },
        { title: "Donate Now", url: "#" },
        { title: "Registered Charity No. 1194117", url: "#" },
      ],
      dataLoading: false,
      footerData: [],
      contactObj: null,
      loading2: false,
      logoData: null,
    };
  },
  props: {
    allPages: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    this.loadData();
    this.LoadLogo();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollTop() {
      window.scrollTo({ top: 10, behavior: "smooth" });
    },
    getRoute(item) {
      return item.actionType == "url"
        ? item.link
        : item.actionType == "page" &&
          item.pageID != this.getPageID(this.$route.params.title)
        ? `/page/${this.getPage(item.pageID)}`
        : "#";
      // return item.actionType == "url"
      //   ? item.link
      //   : item.actionType == "page" && item.pageID != this.$route.params.id
      //   ? `/page/${item.pageID}`
      //   : "#";
    },
    getPage(id) {
      let obj = this.allPages.find((el) => el.id == id);
      return obj ? obj.title.replace(/\s+/g, "-").toLowerCase() : "";
    },
    getPageID(title) {
      let obj = this.allPages.find(
        (el) => el.title.replace(/\s+/g, "-").toLowerCase() == title
      );
      return obj ? obj.id : "";
    },
    loadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Footer/LoadData",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          this.footerData = response.data.data.filter(
            (el) => el.footer.type != "contact"
          );
          let obj = response.data.data.find(
            (el) => el.footer.type == "contact"
          );
          if (obj) {
            this.contactObj = {
              footer: obj.footer,
              links: obj.links.filter((el) => el.actionType != "social"),
              socials: obj.links.filter((el) => el.actionType == "social"),
            };
          } else this.contactObj = null;
          // console.log(this.contactObj);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadLogo() {
      this.loading2 = true;
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.logoData = res.data.data.find((el) => el.type == "footer");
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.loading2 = false));
    },
  },
};
</script>
