<template>
  <div class="py-3 w-100">
    <h1 class="centered-heading left-bordered">
      <span class="highlightheading">Zakat Calculator</span>
    </h1>
    <div class="about-content pt-2">
      <div class="about-content-para adjust-text">
        <p>
          Calculating what you own The first step of working out your Zakat
          payment is to calculate how much wealth you have.
        </p>
      </div>
    </div>
    <div class="mt-3 donation-card-wrapper calculator-card-wrapper">
      <div class="text-center donation-card-heading">
        Page {{ currentStep }} of 6
      </div>
      <div class="donation-card-details calculator-card">
        <div class="calculator-step">
          <form @submit.prevent="handleSubmit">
            <div v-if="currentStep === 1" class="add-animation">
              <h1 class="bottomtitle">Your Assets</h1>
              <div class="row">
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="cash" class="fw-bold"
                    >Cash at home and bank</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step1.cash"
                    id="cash"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="moneyOwed" class="fw-bold"
                    >Money Owed to You</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step1.moneyOwed"
                    id="moneyOwed"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>

            <div v-if="currentStep === 2" class="add-animation">
              <h1 class="bottomtitle">Pensions, Savings & Investments</h1>
              <div class="row">
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="savings" class="fw-bold"
                    >Your Savings and Cash ISAs</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.savings"
                    id="savings"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="childIsas" class="fw-bold"
                    >Child's Savings and Junior ISAs</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.childIsas"
                    id="childIsas"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="pensions" class="fw-bold">Pensions</label>
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.pensions"
                    id="pensions"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <div class="about-content p-0">
                    <div class="about-content-para adjust-text fs-6">
                      <p>
                        <b>Note:</b>
                        Zakat is not due on defined benefit schemes, such as
                        'final salary' schemes. No need to enter this in the
                        calculator. For defined contribution schemes, Zakat is
                        only due in proportion to the underlying Zakatable
                        assets in the fund. As this can be complex to analyse,
                        depending on the type of Pension Fund you have, you can
                        use the following rates: Shariah Funds - use 26% of your
                        pension total Direct Property Fund - use 15% of your
                        pension total Equity Funds - use 27% of your pension
                        total Mixed Asset Funds - use 50% of your pension total
                        Bonds/Gilts/Fixed Interest Funds - use 100% of your
                        pension total For every other type of Pension Fund, or
                        when you are unsure about the tye of fund, it is advised
                        that you use 40% of your pension total for Zakat
                        calculations. As your pension pot's value can fluctuate,
                        please use the balance of these funds as of today, or
                        the latest figure you have.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="lifeIsas" class="fw-bold"
                    >Lifetime ISAs and Long-Term Investments</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.lifeIsas"
                    id="lifeIsas"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="shares" class="fw-bold"
                    >Shares and Stock & Shares ISAs</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.shares"
                    id="shares"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="cryptos" class="fw-bold"
                    >Bitcoin, Ethereum or other cryptocurrencies</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step2.cryptos"
                    id="cryptos"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>

            <div v-if="currentStep === 3" class="add-animation">
              <h1 class="bottomtitle">Gold, Silver & Other Assets</h1>
              <div class="row">
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="gold" class="fw-bold">Value of your Gold</label>
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step3.gold"
                    id="gold"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="silver" class="fw-bold"
                    >Value of your Silver</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step3.silver"
                    id="silver"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="otherAssets" class="fw-bold"
                    >Any other personal assets you own</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step3.otherAssets"
                    id="otherAssets"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>

            <div v-if="currentStep === 4" class="add-animation">
              <h1 class="bottomtitle">Your Business</h1>

              <div class="row">
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="businessAssets" class="fw-bold"
                    >Value of Your Business Assets</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step4.businessAssets"
                    id="businessAssets"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="totalAssets" class="fw-bold"
                    >Total of what you own</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    :value="calculateTotalAssets"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div v-if="currentStep === 5" class="add-animation">
              <h1 class="bottomtitle">Liabilities</h1>

              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="about-content p-0">
                    <div class="about-content-para adjust-text fs-6">
                      <p>
                        Now that we know what you own, we have to take away what
                        you owe to others.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="mortgage" class="fw-bold"
                    >Mortgage Payments (12 months)</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.mortgage"
                    id="mortgage"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="loan" class="fw-bold"
                    >Loan Payments (12 months)</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.loan"
                    id="loan"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="overdraft" class="fw-bold"
                    >Overdraft / Credit Card Balance</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.overdraft"
                    id="overdraft"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="utility" class="fw-bold">Utility Bills</label>
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.utility"
                    id="utility"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="taxes" class="fw-bold"
                    >Income and Other Taxes</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.taxes"
                    id="taxes"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="otherLiabilities" class="fw-bold"
                    >Other Liabilities</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.otherLiabilities"
                    id="otherLiabilities"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="businessLiabilities" class="fw-bold"
                    >Business Liabilities</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    v-model.number="step5.businessLiabilities"
                    id="businessLiabilities"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>

            <div v-if="currentStep === 6" class="add-animation">
              <h1 class="bottomtitle">Summary</h1>

              <div class="row">
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="totalAssets" class="fw-bold"
                    >Total of what you own</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    :value="calculateTotalAssets"
                    disabled
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="totalLiabilities" class="fw-bold"
                    >Total of what you owe</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    :value="calculateTotalLiabilities"
                    disabled
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="totalWealth" class="fw-bold"
                    >Is equal to a total wealth of</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    :value="calculateTotalWealth"
                    disabled
                  />
                </div>
                <div class="col-md-4 col-sm-6 mb-3">
                  <label for="zakat" class="fw-bold">Total Zakat to Pay</label>
                  <input
                    class="form-control"
                    type="number"
                    :value="calculateZakat"
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>

          <button
            v-if="currentStep > 1"
            type="button"
            class="btn btn-secondary px-4 me-3"
            @click="backStep"
          >
            Previous
          </button>
          <button
            v-if="currentStep < 6"
            type="button"
            class="btn btn-success px-4"
            @click="nextStep"
          >
            Next
          </button>
          <button
            v-if="currentStep == 6"
            type="button"
            class="btn btn-warning px-4"
            @click="resetCalculator"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentStep: 1,
      step1: { cash: 0, moneyOwed: 0 },
      step2: {
        savings: 0,
        childIsas: 0,
        pensions: 0,
        lifeIsas: 0,
        shares: 0,
        cryptos: 0,
      },
      step3: { gold: 0, silver: 0, otherAssets: 0 },
      step4: { businessAssets: 0 },
      step5: {
        mortgage: 0,
        loan: 0,
        overdraft: 0,
        utility: 0,
        taxes: 0,
        otherLiabilities: 0,
        businessLiabilities: 0,
      },
    };
  },
  computed: {
    calculateTotalAssets() {
      return (
        this.parseValue(this.step1.cash) +
        this.parseValue(this.step1.moneyOwed) +
        this.parseValue(this.step2.savings) +
        this.parseValue(this.step2.childIsas) +
        this.parseValue(this.step2.pensions) +
        this.parseValue(this.step2.lifeIsas) +
        this.parseValue(this.step2.shares) +
        this.parseValue(this.step2.cryptos) +
        this.parseValue(this.step3.gold) +
        this.parseValue(this.step3.silver) +
        this.parseValue(this.step3.otherAssets) +
        this.parseValue(this.step4.businessAssets)
      );
    },
    calculateTotalLiabilities() {
      return (
        this.parseValue(this.step5.mortgage) +
        this.parseValue(this.step5.loan) +
        this.parseValue(this.step5.overdraft) +
        this.parseValue(this.step5.utility) +
        this.parseValue(this.step5.taxes) +
        this.parseValue(this.step5.otherLiabilities) +
        this.parseValue(this.step5.businessLiabilities)
      );
    },
    calculateTotalWealth() {
      return this.calculateTotalAssets - this.calculateTotalLiabilities;
    },
    calculateZakat() {
      // Zakat is 2.5% of the total wealth
      return this.calculateTotalWealth * 0.025;
    },
  },
  methods: {
    parseValue(val) {
      let x = parseFloat(val);
      return isNaN(x) ? 0 : x;
    },
    nextStep() {
      this.currentStep++;
    },
    backStep() {
      this.currentStep--;
    },
    resetCalculator() {
      this.step1 = { cash: 0, moneyOwed: 0 };
      this.step2 = {
        savings: 0,
        childIsas: 0,
        pensions: 0,
        lifeIsas: 0,
        shares: 0,
        cryptos: 0,
      };
      this.step3 = { gold: 0, silver: 0, otherAssets: 0 };
      this.step4 = { businessAssets: 0 };
      this.step5 = {
        mortgage: 0,
        loan: 0,
        overdraft: 0,
        utility: 0,
        taxes: 0,
        otherLiabilities: 0,
        businessLiabilities: 0,
      };
      this.currentStep = 1;
    },
  },
};
</script>
<style></style>
